import React from 'react'
import styled from 'styled-components'

import media from "../media"

const StyledNavContainer = styled.div`
display:flex;
${media.mobileS`
display:flex;
flex-direction:row;
justify-content:space-around;
padding:5% 0;
`}
${media.tablet`
flex-direction:column;
justify-content:space-around;
`}

`
const StyledExternalNavLink = styled.a`
${media.mobileS`
font-size:0.4rem;
`}
${media.tablet`
font-size:0.8rem;
`}
${media.laptopL`
font-size:1rem;
`}
${media.desktop`
font-size:1.5rem;
margin-bottom:5%;
`}
color:white;
font-family:sans-serif;
text-transform:uppercase;
text-decoration:none;
`


const footerTerms = () => {
    return (
       <StyledNavContainer>
           
             <StyledExternalNavLink href="https://gateway.temporal.cloud/ipns/docs.ts.temporal.cloud">
                 Terms of use
             </StyledExternalNavLink>
           
           
             <StyledExternalNavLink href="https://gateway.temporal.cloud/ipns/docs.dmca.temporal.cloud">
                DMCA policy
             </StyledExternalNavLink>
           
           
             <StyledExternalNavLink href="https://gateway.temporal.cloud/ipns/docs.pp.temporal.cloud">
               Privacy Policy
             </StyledExternalNavLink>
                    
       </StyledNavContainer>
    )
}

export default footerTerms
