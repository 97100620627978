import React from 'react'
import { Link } from "gatsby"
import styled from 'styled-components'
import Logo from '../header/logo'
import FooterNav from './footerNav'
import SocialMedia from './footerSocial'
import Subscription from './footerSub'
import Terms from './footerTerms'
import media from '../media'

const StyledLogoContainer = styled.div`
${
    media.mobileS`
    display:none;
    `
}
${
    media.tablet`
    display:flex;
    flex-direction:row;
    justify-content:center;
    padding:8% 0;
        `
}
`
const StyledSectionContainer = styled.div`
display:flex;
${media.mobileS`
flex-direction:column;
font-size:0.5rem;
`}
${media.tablet`
flex-direction:row;
justify-content:space-around;
align-items:center;
`}


`
const StyledFooterNav = styled.div`
${
    media.mobileS`
    display:none;
   
    `
}
${
    media.tablet`
    display:block;
    width:20%;
    `
}

/* display:block; */
/* width:20%; */
`
const StyledSocialMedia = styled.div`
${
    media.mobileS`
    width:100%;
    `
}
${
    media.tablet`
    width:20%;
    margin-right:5%;
    `
}

/* width:20%;
margin-right:5%; */
`
const StyledSubscription = styled.div`
${
    media.mobileS`
    width:100%;
    `
}
${
    media.tablet`
   width:22%;
    `
}

`
const StyledTerms = styled.div`
${
    media.mobileS`
    width:100%;
    `
}

${
    media.tablet`
    width:17%;
    `
}

`
const StyledFooterCRContainer = styled.div`
display:flex;
justify-content:space-between;
align-items:center;
padding:5% 0;
`
const StyledFooterDecal = styled.div`
background: linear-gradient(45deg, #ff8e53 30%, #fe6b8b 90%);
height:5px;
${media.mobileS`
width:25%;
`}
${media.mobileM`
width:27%;
`}
${media.mobileL`
width:28%;
`}
${media.tablet`
width:30%;
`}
${media.laptop`
width:34%;
`}
${media.laptopL`
width:36%;
`}
`
const StyledFooterCR = styled.a`
color:white;
text-decoration:none;
font-family:sans-serif;
${
    media.mobileS`
    font-size:0.5rem;
    text-align:center;
    padding:2% 3%; 
    `
}
${media.mobileM`
font-size:0.6rem;
`}
${media.mobileL`
font-size:0.7rem;
`}
${
    media.tablet`
    font-size:0.8rem;
    padding:1% 5%; 
    `
}
${
    media.laptop`
    font-size:0.9rem;
    &:hover{
        color:orange;
    }
    `
}
${media.laptopL`
font-size:1rem;
`}
${media.desktop`
font-size:1.6rem;
padding:1% 3%; 
`}

`

const footer = () => {
    return (
        <div style={{backgroundColor:"black"}}>
            <StyledLogoContainer>
                <div style={{width:"25%"}}>
                
                <Link to="/"><Logo/></Link>
                       
                </div>
            </StyledLogoContainer>


            <StyledSectionContainer>


                <StyledFooterNav>
                    <FooterNav/>
                </StyledFooterNav>

            
                <StyledSocialMedia>
                    <SocialMedia/>
                </StyledSocialMedia>

                <StyledSubscription>
                    <Subscription/>
                </StyledSubscription>
                
                <StyledTerms>
                    <Terms/>
                </StyledTerms>

            </StyledSectionContainer>


            <StyledFooterCRContainer>
                <StyledFooterDecal/>
                    <StyledFooterCR href="https://www.rtradetechnologies.com">
                        2020 RTrade Technologies Ltd.
                    </StyledFooterCR>
                <StyledFooterDecal/>
            </StyledFooterCRContainer>


        </div>
    )
}

export default footer
