import React from 'react'
import Img from 'gatsby-image'
import {StaticQuery, graphql } from 'gatsby'
import {Link} from 'gatsby'
import styled from 'styled-components'
import media from '../media'

const StyledImagesContainer = styled.div`
${media.mobileS`
width:80%;
margin:auto;
`}

display:flex;
flex-direction:row;
justify-content:space-around;
align-items:center;
padding-bottom:5%;
margin-top:1%;
`
const StyledLink = styled(Link)`
${media.mobileS`
`}
width:15%;
`
const StyledExternalLink = styled.a`
${media.mobileS`
`}
width:15%;
`
const StyledTitleContainer = styled.div`
display:flex;
flex-direction:column;
align-items:center;
justify-content:center;
`
const StyledTitle = styled.h2`
${media.mobileS`
font-size:1rem;
padding-top:10%;
`}
${media.desktop`
font-size:1.5rem;
padding-top:10%;
`}
font-family:sans-serif;
text-transform:uppercase;
padding-top:3%;
color:white;
font-weight:400;
`
const SocialMedia = () => (

    <StaticQuery
      query={ImagesQ}
      render={data =>
        <div>
          <StyledTitleContainer>
              <StyledTitle>
                Connect With Us
              </StyledTitle>
            
          </StyledTitleContainer>
          <StyledImagesContainer >
            {/* <StyledExternalLink>
              <Img fluid={data.image1.childImageSharp.fluid} />
            </StyledExternalLink> */}
            <StyledExternalLink href="https://twitter.com/temporalcloud" style={{width:"13%"}}>
              <Img fluid={data.image2.childImageSharp.fluid} />
            </StyledExternalLink>
            <StyledExternalLink href ="https://t.me/rtradetemporal" style={{width:"13%"}}>
              <Img fluid={data.image3.childImageSharp.fluid} />
            </StyledExternalLink>
            <StyledExternalLink href="https://github.com/RTradeLtd">
              <Img fluid={data.image4.childImageSharp.fluid} />
            </StyledExternalLink>
            <StyledLink to="/contact">
              <Img fluid={data.image5.childImageSharp.fluid} />
            </StyledLink>
           </StyledImagesContainer>          
        </div>
        }
    />
  

)

export const ImagesQ = graphql`
  fragment SocialMediaImages on File {
    childImageSharp {
      fluid(maxWidth: 40) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }

  query {
    image1: file(relativePath: { eq: "socialMedia/medium.png" }) {
      ...SocialMediaImages
    }

    image2: file(relativePath: { eq: "socialMedia/twitter.png" }) {
      ...SocialMediaImages
    }
    image3: file(relativePath: { eq: "socialMedia/telegram.png" }) {
      ...SocialMediaImages
    }
    image4: file(relativePath: { eq: "socialMedia/github.png" }) {
      ...SocialMediaImages
    }
    image5: file(relativePath: { eq: "socialMedia/email.png" }) {
      ...SocialMediaImages
    }

  }
`

export default SocialMedia