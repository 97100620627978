import React from 'react'
import styled from 'styled-components'
import { Link } from "gatsby"
import media from '../media'

const StyledNavContainer = styled.ul`
display:flex;
${media.mobileS`
flex-direction:row;
`}
${
  media.tablet`
  display:block;
  `
}

flex-direction:column;
justify-content:space-around;


`
const StyledNavItem = styled.li`
${media.tablet`
margin:auto;
`}
${media.desktop`
margin-bottom:5%;
`}
`
const StyledNavLink = styled(Link)`
${media.mobileS`
font-size:0.4rem;
`}
${media.tablet`
font-size:0.8rem;
`}
${media.laptopL`
font-size:1rem;
`}
${media.desktop`
font-size:1.5rem;
`}
color:white;
font-family:sans-serif;
text-transform:uppercase;
text-decoration:none;

`
const StyledExternalNavLink = styled.a`
${media.mobileS`
font-size:0.4rem;
`}
${media.tablet`
font-size:0.8rem;
`}
${media.laptopL`
font-size:1rem;
`}
${media.desktop`
font-size:1.5rem;
`}
color:white;
font-family:sans-serif;
text-transform:uppercase;
text-decoration:none;
`


const footerNav = () => {
    return (
       <StyledNavContainer>
           <StyledNavItem>
             <StyledNavLink to="/about">
                 About
             </StyledNavLink>
           </StyledNavItem>
           <StyledNavItem>
             <StyledNavLink to="/faq">
                 FAQ
             </StyledNavLink>
           </StyledNavItem>
           <StyledNavItem>
             <StyledNavLink to="/dev">
                 Developers
             </StyledNavLink>
           </StyledNavItem>
           <StyledNavItem>
             <StyledExternalNavLink href="https://medium.com/temporal-cloud">
                 Blog
             </StyledExternalNavLink >
           </StyledNavItem>
           <StyledNavItem>
             <StyledNavLink to="/contact">
                 Contact
             </StyledNavLink>
           </StyledNavItem>
           

       </StyledNavContainer>
    )
}

export default footerNav
