import React from 'react'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import media from '../media'

const StyledSubContainer=styled.div`
${media.mobileS`
font-size:0.4rem;
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;

`}
${media.tablet`
display:block;
width:100%;
`}

`
const StyledSubTitle = styled.h2`
color:white;
font-family:sans-serif;
text-transform:uppercase;
font-weight:400;
${media.mobileS`
font-size:1rem;
padding-top:5%;
`}
${media.tablet`
font-size:1rem;
`}
${media.desktop`
font-size:1.5rem;
`}

`


const StyledInput = styled.input`
${media.mobileS`
margin-bottom:2%;
text-align:center;
`}
${media.tablet`
width:50%;
padding:2% 5%;
`}
${media.laptop`
width:50%;
padding:3% 5%;
text-align:left;
`}
${media.laptopL`
font-size:1rem;
width:50%;
padding:3% 5%;
margin-top:0.2%;
`}
/* ${media.desktop`
width:50%;
padding:3% 5%;
margin-top:0.2%;
`} */
border-radius:2px;
font-family:sans-serif;
`

const StyledButton = styled(Button)`
${media.mobileS`
font-size:0.4rem;
`}
${media.tablet`
font-size:0.7rem;
padding:5% 0;
margin-left:-1%;
margin-bottom:1%;
`}
${media.laptopL`
font-size:1rem;
padding:3% 6%;
margin-left:-1%;
margin-bottom:1.5%;
`}

`

const StyledForm = styled.form`
display:flex;
align-items:center;
${media.mobileS`
flex-direction:column;
width:100%;
`}
${media.tablet`
flex-direction:row;
align-items:center;
`}
`


const footerSub = () => {
    return (
       <StyledSubContainer>
           <StyledSubTitle>
            Subscribe to Our Newsbriefs
           </StyledSubTitle>
           <StyledForm action="https://formspree.io/temporal@rtradetechnologies.com" method="POST" >
               <StyledInput type="email" name="email" placeholder="john@smith.com" required />
               <StyledButton variant="contained" color="primary" type="submit" >
                   submit
               </StyledButton>
           </StyledForm>
       </StyledSubContainer>
    )
}

export default footerSub
