import React from "react"
import PropTypes from "prop-types"
import "./layout.css"

import {MuiThemeProvider} from '@material-ui/core/styles'
import theme from './theme'
import {StylesProvider} from "@material-ui/styles"
import Footer from '../components/footer/footer'


const Layout = ({ children }) => {
  return (
      <>
      <StylesProvider injectFirst >
      <MuiThemeProvider theme={theme}>
        
     
          <main>{children}</main>
         
           <Footer/>
        </MuiThemeProvider>
        </StylesProvider>
      </>
    )}
  
Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
