import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import Img from 'gatsby-image'

const logo = () => {
    return (
        <StaticQuery query={getLogo} render={data =>{
           
            return<Img fluid={data.file.childImageSharp.fluid} />
        }} />
    )
}
const getLogo=graphql`
{
 file(relativePath:{eq:"logo.png"}){
  childImageSharp{
   fluid(maxWidth:300){
   #  ...GatsbyImageSharpFixed_tracedSVG
   ...GatsbyImageSharpFluid_noBase64
   }
 }

 }
}`
export default logo
